export const CLIENTS =  {
    NPF_VTB: {
        name: "НПФ ВТБ Пенсионный фонд",
        logo: "/images/client-logos/full/npf_vtb.svg",
        title: "НПФ ВТБ Пенсионный фонд",
        grayLogo: "/images/client-logos/full/npf_vtb.svg",
    },
    OTKRYTIE: {
        name: "Открытие",
        logo: "/images/client-logos/full/OTKRYTIE.png",
        title: "Открытие",
        grayLogo: "/images/client-logos/full/OTKRYTIE.png",
    },
    RED_OS: {
        name: "Ред ОС",
        logo: "/images/client-logos/full/RED_OS.png",
        title: "Ред ОС",
        grayLogo: "/images/client-logos/full/RED_OS.png",
        width: "160px"
    },
    ALT_LINUX: {
        name: "Alt Linux",
        logo: "/images/client-logos/full/ALT_LINUX.png",
        title: "Alt Linux",
        grayLogo: "/images/client-logos/full/ALT_LINUX.png",
        width: "120px"
    },
    ASTRALINUX: {
        name: "ГК Astra Linux",
        logo: "/images/client-logos/full/ASTRA_LINUX.svg",
        title: "ГК Astra Linux",
        grayLogo: "/images/client-logos/full/ASTRA_LINUX.svg",
        width: "228px"
    },
    SECURE_T: {
        name: "SECURE T",
        logo: "/images/client-logos/full/SECURE_T.png",
        title: "SECURE T",
        grayLogo: "/images/client-logos/full/SECURE_T.png",
        width: "100px"
    },
    POSTRGRESPRO: {
        name: "Postgres Professional",
        logo: "/images/client-logos/full/POSTRGRESPRO.svg",
        title: "Postgres Professional",
        grayLogo: "/images/client-logos/full/POSTRGRESPRO.svg",
    },
    ZHKHSAHA: {
        name: "Государственное унитарное предприятие «ЖИЛИЩНО-КОММУНАЛЬНОЕ ХОЗЯЙСТВО РЕСПУБЛИКИ САХА ЯКУТИЯ»",
        logo: "/images/client-logos/full/ZHKHSAHA.png",
        title: "Государственное унитарное предприятие «ЖИЛИЩНО-КОММУНАЛЬНОЕ ХОЗЯЙСТВО РЕСПУБЛИКИ САХА ЯКУТИЯ»",
        grayLogo: "/images/client-logos/full/ZHKHSAHA.png",
    },
    UNIVER: {
        name: "Универ Сбережения",
        logo: "/images/client-logos/full/UNIVER.svg",
        title: "Универ Сбережения",
        grayLogo: "/images/client-logos/full/UNIVER.svg",
    },
    BLAGOSOST: {
        name: "НПФ «БЛАГОСОСТОЯНИЕ»",
        logo: "/images/client-logos/full/BLAGOSOST.png",
        title: "Универ Сбережения",
        grayLogo: "/images/client-logos/full/BLAGOSOST.png",
    },
    VODOKANALKKALIN: {
        name: "Государственное предприятие Калининградской области «Водоканал» (ГП КО «Водоканал»)",
        logo: "/images/client-logos/full/VODOKANALKKALIN.svg",
        title: "ГП КО «Водоканал»",
        grayLogo: "/images/client-logos/full/VODOKANALKKALIN.svg",
        width: "190px"
    },
    UTC: {
        name: "Единый налоговый центр",
        logo: "/images/client-logos/full/UTC.png",
        title: "Единый налоговый центр",
        grayLogo: "/images/client-logos/gray/UTC.png"
    },
    INS_RGS: {
        name: "РОСГОССТРАХ",
        logo: "/images/client-logos/full/INS_RGS.svg",
        title: "РОСГОССТРАХ",
        grayLogo: "/images/client-logos/gray/INS_RGS.svg",
        width: "130px"
    },
    INS_ZETTA: {
        name: "Страховая компания «Зетта Страхование»",
        logo: "/images/client-logos/full/INS_ZETTA.svg",
        title: "Страховая компания «Зетта Страхование»",
        grayLogo: "/images/client-logos/gray/INS_ZETTA.svg",
        width: "130px"
    },
    INS_VTB: {
        name: "ВТБ Страхование",
        logo: "/images/client-logos/full/INS_VTB.svg",
        title: "ВТБ Страхование",
        grayLogo: "/images/client-logos/gray/INS_VTB.svg",
        width: "130px"
    },
    INS_TINKOFF: {
        name: "Тинькофф Страхование",
        logo: "/images/client-logos/full/INS_TINKOFF.svg",
        title: "Тинькофф Страхование",
        grayLogo: "/images/client-logos/gray/INS_TINKOFF.svg",
        width: "130px"
    },
    INS_SOGLASIE: {
        name: "Страховая компания «Согласие»",
        logo: "/images/client-logos/full/INS_SOGLASIE.svg",
        title: "Страховая компания «Согласие»",
        grayLogo: "/images/client-logos/gray/INS_SOGLASIE.svg",
        width: "130px"
    },
    INS_RENINS: {
        name: "Ренессанс страхование",
        logo: "/images/client-logos/full/INS_RENINS.svg",
        title: "Ренессанс страхование",
        grayLogo: "/images/client-logos/gray/INS_RENINS.svg",
        width: "130px"
    },
    INS_INGOS: {
        name: "СПАО \"Ингосстрах\"",
        logo: "/images/client-logos/full/INS_INGOS.svg",
        title: "Ингосстрах",
        grayLogo: "/images/client-logos/gray/INS_INGOS.svg",
        width: "130px"
    },
    INS_ERV: {
        name: "ERV",
        logo: "/images/client-logos/full/INS_ERV.svg",
        title: "ERV",
        grayLogo: "/images/client-logos/gray/INS_ERV.svg",
        width: "130px"
    },
    INS_EQIP: {
        name: "Страховая компания \"Экип\"",
        logo: "/images/client-logos/full/INS_EQIP.svg",
        title: "Страховая компания \"Экип\"",
        grayLogo: "/images/client-logos/gray/INS_EQIP.svg",
        width: "60px"
    },
    INS_ALLIANZ: {
        name: "Allianz - страховая компания",
        logo: "/images/client-logos/full/INS_ALLIANZ.svg",
        title: "Allianz - страховая компания",
        grayLogo: "/images/client-logos/gray/INS_ALLIANZ.svg",
        width: "130px"
    },
    INS_ALFA: {
        name: "Альфа Страхование",
        logo: "/images/client-logos/full/INS_ALFA.svg",
        title: "Альфа Страхование",
        grayLogo: "/images/client-logos/gray/INS_ALFA.svg",
        width: "130px"
    },
    INS_ABSOLUT: {
        name: "Абсолют Страхование",
        logo: "/images/client-logos/full/INS_ABSOLUT.svg",
        title: "Абсолют Страхование",
        grayLogo: "/images/client-logos/gray/INS_ABSOLUT.svg",
        width: "130px"
    },
    BANK_ABSOLUT: {
        name: "АбсолютБанк",
        logo: "/images/client-logos/full/BANK_ABSOLUT.png",
        title: "АбсолютБанк",
        grayLogo: "/images/client-logos/gray/BANK_ABSOLUT.png"
    },
    MIG_CREDIT: {
        name: "Миг Кредит",
        logo: "/images/client-logos/full/MIG_CREDIT.svg",
        title: "Миг Кредит",
        grayLogo: "/images/client-logos/gray/MIG_CREDIT.svg",
        width: "89px"
    },
    ATOMGARANT: {
        name: "АКЦИОНЕРНОЕ ОБЩЕСТВО НЕГОСУДАРСТВЕННЫЙ<span br-768></span> ПЕНСИОННЫЙ ФОНД «АТОМГАРАНТ»",
        logo: "/images/client-logos/full/ATOMGARANT.png",
        title: "АКЦИОНЕРНОЕ ОБЩЕСТВО НЕГОСУДАРСТВЕННЫЙ<span br-768></span> ПЕНСИОННЫЙ ФОНД «АТОМГАРАНТ»",
        grayLogo: "/images/client-logos/gray/ATOMGARANT.png"
    },
    EDINIY_CENTER_VZYSKANIYA: {
        name: "ООО \"Единый Центр Взыскания\"",
        logo: "/images/client-logos/full/EDINIY_CENTER_VZYSKANIYA.png",
        title: "Единый Центр Взыскания",
        grayLogo: "/images/client-logos/gray/EDINIY_CENTER_VZYSKANIYA.png",
        width: "180px"
    },
    DEVINO_TELECOM: {
        name: "Devino Telecom",
        logo: "/images/client-logos/full/DEVINO_TELECOM.svg",
        title: "Devino Telecom",
        grayLogo: "/images/client-logos/gray/DEVINO_TELECOM.svg"
    },
    NPF_STROIKOMPLEX: {
        name: "НПФ СТРОЙКОМПЛЕКС",
        logo: "/images/client-logos/full/NPF_STROIKOMPLEX.png",
        title: "НПФ СТРОЙКОМПЛЕКС",
        grayLogo: "/images/client-logos/gray/NPF_STROIKOMPLEX.png"
    },
    NPF_ALMAZ: {
        name: "НПФ АЛМАЗНАЯ ОСЕНЬ",
        logo: "/images/client-logos/full/NPF_ALMAZ.png",
        title: "НПФ АЛМАЗНАЯ ОСЕНЬ",
        grayLogo: "/images/client-logos/gray/NPF_ALMAZ.png",
        width: "173px"
    },

    DINORD: {
        name: "DINORD",
        logo: "/images/client-logos/full/DINORD.png",
        title: "DINORD",
        grayLogo: "/images/client-logos/gray/DINORD.png"
    },
    ESP: {
        name: "Единая система продаж",
        logo: "/images/client-logos/full/ESP.jpg",
        title: "Единая система продаж",
        grayLogo: "/images/client-logos/gray/ESP.jpg"
    },
    NPF_VOLGACAPITAL: {
        name: "АО \"НПФ ВОЛГА-КАПИТАЛ\"",
        logo: "/images/client-logos/full/NPF_VOLGACAPITAL.png",
        title: "НПФ ВОЛГА-КАПИТАЛ",
        grayLogo: "/images/client-logos/gray/NPF_VOLGACAPITAL.png"
    },
    ARZAMAS_VODOKANAL: {
        name: "ООО \"Арзамасский водоканал\"",
        logo: "/images/client-logos/full/ARZAMAS_VODOKANAL.png",
        title: "Арзамасский водоканал",
        grayLogo: "/images/client-logos/gray/ARZAMAS_VODOKANAL.png"
    },
    KVG_VODOKANAL: {
        name: "ООО «Концессии водоснабжения Геленджик»",
        logo: "/images/client-logos/full/KVG_VODOKANAL.svg",
        title: "Арзамасский водоканал",
        grayLogo: "/images/client-logos/gray/KVG_VODOKANAL.svg",
        width: "195px"
    },
    RUS_TELECOM: {
        name: "Русь Телеком",
        logo: "/images/client-logos/full/RUS_TELECOM.png",
        title: "Русь Телеком",
        grayLogo: "/images/client-logos/gray/RUS_TELECOM.png",
        width: "158px"
    },
    RENENSANS_LIFE_AND_PENSION: {
        name: "РЕНЕССАНС ЖИЗНЬ И ПЕНСИИ НПФ",
        logo: "/images/client-logos/full/RENENSANS_LIFE_AND_PENSION.png",
        title: "РЕНЕССАНС ЖИЗНЬ И ПЕНСИИ НПФ",
        grayLogo: "/images/client-logos/gray/RENENSANS_LIFE_AND_PENSION.jpg"
    },
    FORTRAID: {
        name: "ФОРТРЕЙД ООО",
        logo: "/images/client-logos/full/FORTRAID.png",
        title: "ФОРТРЕЙД ООО",
        grayLogo: "/images/client-logos/gray/FORTRAID.png"
    },
    NPF_URALOBORONZVODSKIY: {
        name: "УРАЛОБОРОНЗАВОДСКИЙ НПФ",
        logo: "/images/client-logos/full/NPF_URALOBORONZVODSKIY.png",
        title: "УРАЛОБОРОНЗАВОДСКИЙ НПФ",
        grayLogo: "/images/client-logos/gray/NPF_URALOBORONZVODSKIY.jpg"
    },
    NPF_SUN_COAST: {
        name: "СБЕРФОНД СОЛНЕЧНЫЙ БЕРЕГ АО НПФ",
        logo: "/images/client-logos/full/NPF_SUN_COAST.png",
        title: "СБЕРФОНД СОЛНЕЧНЫЙ БЕРЕГ АО НПФ",
        grayLogo: "/images/client-logos/gray/NPF_SUN_COAST.gif",
        width: "180px"
    },
    UK_RUSACTIVE: {
        name: "ООО \"УК \"РУСАКТИВ\"",
        logo: "/images/client-logos/full/UK_RUSACTIVE.png",
        title: "ООО \"УК \"РУСАКТИВ\"",
        grayLogo: "/images/client-logos/gray/UK_RUSACTIVE.gif"
    },
    KIROVS_MILITARY_HOSPITAL_INSTITUTE: {
        name: "Военно-медицинская академия имени С. М. Кирова",
        logo: "/images/client-logos/full/KIROVS_MILITARY_HOSPITAL_INSTITUTE.png",
        title: "Военно-медицинская академия имени С. М. Кирова",
        grayLogo: "/images/client-logos/gray/KIROVS_MILITARY_HOSPITAL_INSTITUTE.png"
    },
    LINGVOED: {
        name: "LingvoEd языковая школа",
        logo: "/images/client-logos/full/LINGVOED.png",
        title: "LingvoEd языковая школа",
        grayLogo: "/images/client-logos/gray/LINGVOED.png"
    },
    NPF_HANTY_MANS: {
        name: "АО \"ХАНТЫ-МАНСИЙСКИЙ НПФ\"",
        logo: "/images/client-logos/full/NPF_HANTY_MANS.svg",
        title: "АО \"ХАНТЫ-МАНСИЙСКИЙ НПФ\"",
        grayLogo: "/images/client-logos/gray/NPF_HANTY_MANS.svg",
        width: "259px"
    },
    LIGA_DENEG: {
        name: "ООО МКК \"Лига денег\"",
        logo: "/images/client-logos/full/LIGA_DENEG.svg",
        title: "ООО МКК \"Лига денег\"",
        grayLogo: "/images/client-logos/gray/LIGA_DENEG.svg",
        width: "160px"
    },
    NPF_SAFMAR: {
        name: "НПФ Сафмар",
        logo: "/images/client-logos/full/NPF_SAFMAR.svg",
        title: "НПФ Сафмар",
        grayLogo: "/images/client-logos/gray/NPF_SAFMAR.svg",
        width: "189px"
    },
    UK_TFG: {
        name: "УК ТФГ АО",
        logo: "/images/client-logos/full/UK_TFG.svg",
        title: "УК ТФГ АО",
        grayLogo: "/images/client-logos/gray/UK_TFG.svg",
        width: "222px"
    },
    NPF_ALLIENCE: {
        name: "АЛЬЯНС АО НПФ",
        title: "НПФ АЛЬЯНС",
        logo: "/images/client-logos/full/NPF_ALLIENCE.svg",
        grayLogo: "/images/client-logos/gray/NPF_ALLIENCE.svg",
        width: "320px"
    },
    NPF_GAZFOND: {
        name: "НПФ ГАЗФОНД ПЕНСИОННЫЕ НАКОПЛЕНИЯ ОАО",
        title: "НПФ Газфонд",
        logo: "/images/client-logos/full/NPF_GAZFOND.svg",
        grayLogo: "/images/client-logos/gray/NPF_GAZFOND.svg",
        width: "173px"
    },
    NPF_AKVILON: {
        name: "МНПФ \"АКВИЛОН\"",
        title: "МНПФ \"АКВИЛОН\"",
        logo: "/images/client-logos/full/NPF_AKVILON.png",
        grayLogo: "/images/client-logos/gray/NPF_AKVILON.png",
        width: "300px"
    },
    UK_APRIL_CAPITAL: {
        name: "АО УК \"Апрель Капитал\"",
        title: "АО УК \"Апрель Капитал\"",
        logo: "/images/client-logos/full/UK_APRIL_CAPITAL.svg",
        grayLogo: "/images/client-logos/gray/UK_APRIL_CAPITAL.svg",
        width: "320px"
    },
    NPF_BIG: {
        name: "АО МНПФ \"БОЛЬШОЙ\"",
        title: "НПФ Большой",
        logo: "/images/client-logos/full/NPF_BIG.svg",
        grayLogo: "/images/client-logos/gray/NPF_BIG.svg",
        width: "89px"
    },
    NPF_NATIONAL: {
        name: "АО \"Национальный НПФ\"",
        title: "НПФ Национальный",
        logo: "/images/client-logos/full/NPF_NATIONAL.svg",
        grayLogo: "/images/client-logos/gray/NPF_NATIONAL.svg",
        width: "120px"
    },
    MAINS: {
        name: "ООО \"МЭЙНС КОНСАЛТИНГ\"",
        title: "ООО \"МЭЙНС КОНСАЛТИНГ\"",
        logo: "/images/client-logos/full/MAINS.svg",
        grayLogo: "/images/client-logos/gray/MAINS.svg",
        width: "130px"
    },
    HKF_BANK: {
        name: "ООО \"ХКФ БАНК\"",
        title: "ООО \"ХКФ БАНК\"",
        logo: "/images/client-logos/full/HKF_BANK.svg",
        grayLogo: "/images/client-logos/gray/HKF_BANK.svg",
        width: "102px"
    },
    NN_VODOKANAL: {
        name: "АО «Нижегородский водоканал»",
        title: "АО «Нижегородский водоканал»",
        logo: "/images/client-logos/full/NN_VODOKANAL.svg",
        grayLogo: "/images/client-logos/gray/NN_VODOKANAL.svg",
        width: "200px"
    },
    CONCESS_WATER_VOLGOGRAD: {
        name: "Концессия водоснабжения г. Волгограда",
        logo: "/images/client-logos/full/CONCESS_WATER_VOLGOGRAD.svg",
        title: "Концессия<span br-768></span> водоснабжения<span br-768></span> г. Волгограда",
        grayLogo: "/images/client-logos/gray/CONCESS_WATER_VOLGOGRAD.svg",
        width: "180px"
    },
    CONCESS_WARM_WATER_VOLGOGRAD: {
        name: "Концессия теплоснабжения г. Волгограда",
        logo: "/images/client-logos/full/CONCESS_WARM_WATER_VOLGOGRAD.svg",
        title: "Концессия<span br-768></span> теплоснабжения<span br-768></span> г. Волгограда",
        grayLogo: "/images/client-logos/gray/CONCESS_WARM_WATER_VOLGOGRAD.svg",
        width: "180px"
    },
    CONCESS_WATER_SARATOV: {
        name: "Концессия водоснабжения г. Cаратова",
        logo: "/images/client-logos/full/CONCESS_WATER_SARATOV.svg",
        title: "Концессия<span br-768></span> водоснабжения<span br-768></span> г. Cаратова",
        grayLogo: "/images/client-logos/gray/CONCESS_WATER_SARATOV.svg",
        width: "192px"
    },
    READY4: {
        name: "Ready4",
        title: "Онлайн маркет всех<span br-768></span> видов страхования",
        logo: "/images/client-logos/full/READY4.svg",
        grayLogo: "/images/client-logos/gray/READY4.svg",
        width: "130px"
    },
    ARS_POLIS: {
        name: "АРС Полис Страховой брокер",
        title: "АРС Полис<span br-768></span> Страховой брокер",
        logo: "/images/client-logos/full/ARS_POLIS.svg",
        grayLogo: "/images/client-logos/gray/ARS_POLIS.svg",
        width: "130px"
    },
    NPF_ROSTEH: {
        name: "НПФ Ростех",
        title: "НПФ Ростех",
        logo: "/images/client-logos/full/NPF_ROSTEH.svg",
        grayLogo: "/images/client-logos/gray/NPF_ROSTEH.svg",
        width: "110px"
    },
    VODOKANAL_YAKUT: {
        name: "Водоканал Якутск",
        title: "Водоканал Якутск",
        logo: "/images/client-logos/full/VODOKANAL_YAKUT.png",
        grayLogo: "/images/client-logos/gray/VODOKANAL_YAKUT.png"
    },
    AVTOCOD: {
        name: "Автокод",
        title: "Автокод",
        logo: "/images/client-logos/full/AVTOCOD.svg",
        grayLogo: "/images/client-logos/gray/AVTOCOD.svg",
        width: "130px"
    }
}


<script setup lang="ts">
import {defineProps, nextTick, onMounted, ref, withDefaults, onBeforeUnmount} from "vue";
import Swiper from 'swiper';
import { Navigation, Autoplay } from 'swiper/modules';
import {CLIENTS} from "@/configs/clients";
import Sprites from "~/components/icon/Sprites.vue";

const $props = withDefaults(defineProps<{
  clients: {
    href?: string,
    code: keyof typeof CLIENTS
  }[]
}>(), {
  clients: () => ([])
})

let swiper : Swiper = null;
const swiperEl: HTMLDivElement = ref(null);
const id = ref(null);

const prevBtnId = computed(() => `${id.value}-prev`);
const nextBtnId = computed(() => `${id.value}-next`);

onMounted(() => {
  id.value = 'slider_'+Math.round(Math.random() * 100000);

  nextTick(() => {
    swiper = new Swiper(`#${id.value}`, {
      modules: [Navigation, Autoplay],
      direction: 'horizontal',
      // loop: true,
      spaceBetween: "40px",
      centeredSlides: false,
      slidesPerView: "auto",
      navigation: {
        nextEl: '#'+nextBtnId.value,
        prevEl: '#'+prevBtnId.value,
      },
      autoplay: {
        enabled: true,
        delay: 1300,
        pauseOnMouseEnter: true,
        disableOnInteraction: true,
      },
      breakpoints: {
        768: {
          spaceBetween: "40px",
        },
        0: {
          spaceBetween: 0
        }
      }
      // centeredSlides: true
    });
  });
})

onBeforeUnmount(() => {
  swiper?.destroy();
})
</script>

<template>
  <div class="solution-content-slider clients-list">
    <div class="clients-list-header">
      <div class="navigation">
        <div class="navigation-prev" :id="prevBtnId"><icon name="icon-slide-next" style="transform: rotate(180deg)"/></div>
        <div class="navigation-next" :id="nextBtnId"><icon name="icon-slide-next" /></div>
      </div>
    </div>
    <div class="clients-list-slider">
      <div class="swiper" ref="swiperEl" :id="id">
        <!-- Additional required wrapper -->
        <div class="swiper-wrapper">
          <div
            class="swiper-slide"
            v-for="client in clients"
            :key="`client-${client}`"
          >
            <component :is="!!client.href ? 'a' : 'div'" :href="client">
              <img
                alt="Screenshot"
                loading="lazy"
                :alt="CLIENTS[client.code].name"
                :src="CLIENTS[client.code].logo"
              />
            </component>
          </div>
        </div>
      </div>
    </div>
  </div>

  <sprites>
    <symbol id="icon-slide-next" viewBox="0 0 43 43" fill="none">
      <rect width="43" height="43" rx="5" transform="matrix(-1 0 0 1 43 0)" fill="#5667FF"/>
      <path d="M18.5 14L26 21.5L18.5 29" stroke="white" stroke-width="2"/>
    </symbol>
  </sprites>
</template>

<style scoped lang="css">
@import "@/node_modules/swiper/swiper-bundle.min.css";
</style>


<style scoped lang="scss">
.clients-list {
  margin-top: 40px;
  position: relative;
  //margin-bottom: 100px;
  &-header {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 100%;
    align-items: flex-end;
    z-index: 22;
    pointer-events: none!important;

    .navigation {
      display: flex;
      justify-content: space-between;
      padding: 0 15px;
      gap: 30px;

      @include onXS {
        display: none;
      }

      .icon {
        width: 43px;
        height: 43px;
      }

      .navigation-prev,
      .navigation-next {
        pointer-events: all;
      }
    }
  }
  &-slider {
    padding: 0 80px;

    @include onXS {
      padding: 0px;
    }
  }

  img {
    filter: grayscale(1);
    opacity: .45;
    width: 100%;
    height: 100%;
    max-height: 120px;
    max-width: 250px;
    transition: all .25s ease;
    user-select: none;


    @include onSM {
      max-height: 80px;
      max-width: 150px;
    }
    &:hover {
      opacity: 1;
      filter: grayscale(0);
    }
  }
  :deep(.swiper-slide) {
    width: 280px;
    padding: 0 20px;

    @include onSM {
      width: auto;
      padding: 0 15px;
      display: flex;
      align-items: center;
    }
  }
  :deep(.swiper-wrapper) {
    align-items: center;
    //gap: 40px;
  }
  //.swiper {
  //  width: calc(100vw - 20px);
  //  padding-left: calc((100vw - 1242px) / 2);
  //  @include onSM {
  //    width: 100%;
  //    margin: 0;
  //  }
  //}
}
</style>

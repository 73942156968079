<script setup lang="ts">
import {onBeforeUnmount, onMounted, defineProps, withDefaults} from "vue";

const $props = withDefaults(defineProps<{
  animation?: 'fade' | 'slide' | 'jump'
  tag?: keyof HTMLElementTagNameMap
}>(), {
  tag: "div",
  animation: ""
})

function isInScreen(el: HTMLElement) {
  const rect = el.getBoundingClientRect();
  return (
    rect.left >= 0 &&
    rect.top <= (window.screenY + window.innerHeight - 100)
  );
}
function onScroll() {
  document.querySelectorAll("[data-scroll-anim]").forEach((el: Element) => {
    if(!(el instanceof HTMLElement)) return;
    const htmlEl = el as HTMLElement;
    if(isInScreen(htmlEl)) {
      htmlEl.removeAttribute("data-scroll-anim")
      htmlEl.classList.add("animated");
    }
  })
}
onMounted(() => {
  // @ts-ignore
  if(!window.onscrollHandler) {
    // @ts-ignore
    window.onscrollHandler = 1;
    document.addEventListener("scroll", onScroll);
    setTimeout(() => onScroll(), 100)
  }

  try {
    if (/bot|googlebot|crawler|spider|robot|crawling|Chrome-Lighthouse/i.test(window.navigator.userAgent)) {
      // For google robot
      document.body.classList.add("--bot");
      document.querySelectorAll("[data-scroll-anim]").forEach((el: Element) => {
        if (!(el instanceof HTMLElement)) return;
        const htmlEl = el as HTMLElement;
        htmlEl.removeAttribute("data-scroll-anim")
        htmlEl.classList.add("animated")
      });
    }
  }catch(e) {}
})
onBeforeUnmount(() => {
  // @ts-ignore
  if(!window.onscrollHandler) {
    // @ts-ignore
    window.onscrollHandler -= 1;
    // @ts-ignore
    if(window.onscrollHandler === 0) {
      console.log("REMOVE")
      document.removeEventListener("scroll", onScroll);
    }
  }
})
</script>

<template>
  <component
    :is="tag"
    data-scroll-anim
    class="scroll-animated"
    :class="animation"
  >
    <slot/>
  </component>
</template>

<style lang="scss">
</style>

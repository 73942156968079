<script setup lang="ts">
import type {IAccordionItem, IAccordionItems} from "./types";
import  Icon from "@/components/icon/Icon.vue"

export interface IAccordionOptions {
  title: string,
  open?: boolean,
  forceExpandOn?: number
}

const $props = withDefaults(defineProps<IAccordionOptions>(), {
  open: false
});

const state : Ref<IAccordionItem> = ref({
  expand: $props.open === true,
  title: $props.title,
  id: Math.round(Math.random() * 1000)
})

const forceExpand = ref(false);
const $items : Ref<IAccordionItems | undefined> | undefined = inject<IAccordionItems | undefined>('accordion-tabs');
function onResize() {
  if(!!$props.forceExpandOn && state.value) {
    forceExpand.value = window.innerWidth <= $props.forceExpandOn
  }
}

onMounted(() => {
  if(!!$items && !!$items.value)
    $items.value[state.value.id] = state;

  onResize();
  window.addEventListener('resize', onResize);
})

onBeforeUnmount(() => {
  if(!!$items && !!$items.value && !!$items.value[state.value.id])
    delete $items.value[state.value.id];

  window.removeEventListener('resize', onResize);
})
</script>

<template>
  <div class="accordion-item" :class="{ 'accordion-item--open': state.expand || forceExpand }">
    <a href="#" @click.prevent="state.expand = !state.expand" class="accordion-item__title" >
      <h3 class="text">{{ $props.title }}</h3>
      <div class="actions" v-if="!forceExpand"><icon name="icon-accordion-arrow-down"/></div>
    </a>
    <div class="accordion-item__content" v-if="state.expand || forceExpand">
      <slot/>
    </div>
  </div>
</template>

<style lang="scss">
body {

}
</style>

<script setup lang="ts">
import type {IAccordionItems} from "./types";

const tabs : Ref<IAccordionItems> = ref({});
provide('accordion-tabs', tabs);
</script>

<template>
  <div class="accordion">
    <slot/>
  </div>
</template>

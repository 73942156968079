<script setup lang="ts">
import { withDefaults, defineProps } from "vue";

const $props = withDefaults(defineProps<{
  src: string,
  alt?: string,
  width?: string
}>(), {
  src: "",
  alt: "",
  width: "auto"
})

const isMounted = ref(false);
onMounted(() => isMounted.value = true);

const isLoaded = ref(false);
function onImgLoaded() {
  isLoaded.value = true;
}

const isSVG = computed(() => $props.src.includes('.svg'));

</script>

<template>
  <div class="img" v-show="isMounted">
    <img
      v-if="isSVG"
      :src="src"
      :alt="alt"
    >
   <template v-else>
     <nuxt-img
       :src="src"
       :alt="alt || 'изображение'"
       quality="1"
       v-if="!isLoaded"
       :width="width"
       class="preview-img"
     />
     <nuxt-img
       :src="src"
       :alt="alt || 'изображение'"
       loading="lazy"
       :width="width"
       @load="onImgLoaded"
       :class="{
        'not-loaded-img': !isLoaded
      }"
     />
   </template>

    <span class="img-loader" v-if="!isSVG && !isLoaded"></span>
  </div>
</template>

<style lang="scss">
@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: .7;
  }
  100% {
    opacity: 1;
  }
}


.img-loader {
  width: 60px;
  height: 18px;
  display: inline-block;
  position: absolute;
  top: calc(50% - (18px / 2));
  left: calc(50% - (60px / 2));

  &:after {
    content: "";
    display: block;
    position: absolute;
    left: -5px;
    height: calc(100% + 10px);
    top: -5px;
    right: -5px;
    background: $VIOLET;
    border-radius: 5px;
    z-index: 1;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background-color: #fff;
    border: 1px solid #fff;
    border-radius: 4px;
    background: linear-gradient(45deg, transparent 49%, #fff 50%, #fff 50%, transparent 51%, transparent), linear-gradient(-45deg, transparent 49%, #fff 50%, #fff 50%, transparent 51%, transparent);
    @include font-size(15px);
    background-size: 1em 1em;
    box-sizing: border-box;
    animation: barStripe 0.5s linear infinite;
  }
}

@keyframes barStripe {
  0% {
    background-position: 1em 0;
  }
  100% {
    background-position: 0 0;
  }
}

.preview-img {
  filter: blur(10px)!important;
  animation: pulse 1s infinite ease-in-out;
}

.not-loaded-img {
  position: fixed;
  left: 0;
  top: 0;
  opacity: 0;
  width: 0px;
  height: 0px;
}
.img {
  position: relative;
}
</style>

<script setup lang="ts">
import {ref, withDefaults, defineProps} from "vue";
import Sprites from "@/components/icon/Sprites.vue";
import Icon from "~/components/icon/Icon.vue";

const $props = withDefaults(defineProps<{
  h1?: String,
  h2?: String,
  showMoreButton?: Boolean
}>(),{
  h1: undefined,
  h2: undefined,
  showMoreButton: false
})

const mainWrapperEl = ref(null);
function scrollToContent(event: Event) {
  try {
    const el = mainWrapperEl.value as HTMLElement | Node;

    let depth = 5;
    let elBefore = el;

    while (!!depth) {
      const next = elBefore.nextSibling;
      if (!next) return;
      if (next instanceof HTMLElement) {
        if (next.style.display !== "none") {
          window.scrollTo({
            top: next.getBoundingClientRect()?.top + window.scrollY - 20,
            behavior: "smooth"
          })
          return;
        }
      }
      if (!(next instanceof Node)) return;

      elBefore = next;
      depth--;
    }
  } catch (e) {
    console.error("Unable to scroll to el ", e, event);
  }
}
</script>

<template>
  <div class="main-page-header" ref="mainWrapperEl">
    <div class="container main-page-header-container">
      <div class="main-page-header__h1" v-if="!!$slots.h1 || !!h1">
        <slot name="h1"/>
        <h1 v-if="!!h1 && !$slots.h1" v-html="h1"></h1>
      </div>

      <div class="main-page-header__h2" v-if="!!$slots.h2 || !!h2">
        <slot name="h2"/>
        <h2 v-if="!!h2 && !$slots.h2" v-html="h2"></h2>
      </div>

      <slot name="content"/>

      <a
        href="#"
        class="main-page-header__show-more-btn"
        v-if="showMoreButton"
        @click.prevent="scrollToContent"
      >
        <icon name="icon-showmore-arrow-down"/>
      </a>
    </div>
  </div>

  <sprites v-if="showMoreButton">
    <svg id="icon-showmore-arrow-down" viewBox="0 0 70 70" fill="none">
      <rect width="70" height="70" rx="5" fill="#5667FF"/>
      <path d="M33.9393 48.0607C34.5251 48.6464 35.4749 48.6464 36.0607 48.0607L45.6066 38.5147C46.1924 37.9289 46.1924 36.9792 45.6066 36.3934C45.0208 35.8076 44.0711 35.8076 43.4853 36.3934L35 44.8787L26.5147 36.3934C25.9289 35.8076 24.9792 35.8076 24.3934 36.3934C23.8076 36.9792 23.8076 37.9289 24.3934 38.5147L33.9393 48.0607ZM33.5 23L33.5 47L36.5 47L36.5 23L33.5 23Z" fill="white"/>
    </svg>
  </sprites>
</template>

<style scoped lang="scss">
.main-page-header {
  $ROOT: &;

  background: $BG_DARK;
  color: #fff;
  margin-bottom: 60px;
  &:has( #{$ROOT}__show-more-btn) {
    margin-bottom: 100px;
  }

  &-container {
    position: relative;
    padding-top: 40px;
    padding-bottom: 40px;

    &:has( #{$ROOT}__show-more-btn) {
      padding-bottom: calc(35px + 40px);
    }
  }

  &__h1 {
    @include font-size(50px);
    font-weight: 600;
    line-height: 1.2em;
    letter-spacing: -2px;
    margin-bottom: 10px;
    &:has(+#{$ROOT}__h2) {
      margin-bottom: 45px;
      border-bottom: 1px $GRAY_LINE solid;
      padding-bottom: 40px;
    }
  }

  &__h2 {
    @include font-size(24px);
    font-weight: 400;
    line-height: 1.5em;
    letter-spacing: -0.96px;
    margin-bottom: 40px;
  }

  &__show-more-btn {
    position: absolute;
    bottom: -35px;
    left: 15px;
    transition: all .25s ease;
    .icon {
      width: 70px;
      height: 70px;
      transition: all .25s ease;
    }
    &:hover {
      transform: translateY(2px);
      .icon {
        box-shadow: 0 4px 20px 0 rgba(86, 118, 233, 0.25);
      }
    }
  }
}
</style>
